<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp" class="vi-hero">
    <section id="hero" class="align-items-center pl-4 pr-4 overflow-hidden">
      <div class="container-fluid web-dispflex">
        <div
          class="web-ellipse wow web-fadeIn"
          data-wow-delay="0.1s"
          style=" -webkit-animation-delay: 0.2s; -moz-animation-delay: 0.2s; animation-delay: 0.2s;"
        >
          <div class="web-ellipsecont wow web-fadeInLeft" style="margin-top: 3%;">
            <div class="web-hmtab">
              <button
                class="hmtablinks active"
                @click="openhmSearch($event, 'sSearch')"
              >
                School Search
              </button>
              <!-- <button
                class="hmtablinks"
                @click="openhmSearch($event, 'tjSearch')"
              >
                Teachers Jobs
              </button> -->
            </div>
            <div id="sSearch" class="web-hmtabcontent" style="display:block">
              <div class="home-search-form">
                <div class="form-row">
                  <div class="form-group input-group col-sm-12 pr-md-3">
                    <span class="web-has-float-label">
                        <v-select
                          placeholder="Search School Name"
                          class="form-control web-form-input"
                          id="autocomplete"
                          label="accountName"
                          name="schoolName"
                          v-model="sname"
                          :options="accountList"
                          @search="getSchoolList"
                        >
                          <span v-if="!showOption" style="display: none !important;" slot="no-options"></span>
                        </v-select>

                        <label for="schoolName">School Name <span class="required">*</span></label>
                      </span>
                  </div>
                 
                </div>
                <div class="form-row">
                  <div class="form-group input-group col-sm-12 mt-4">
                    <span class="web-has-float-label">
                     <vue-google-autocomplete
                        ref="address"
                        id="map"
                        v-on:inputChange="clearAddress"
                        placeholder="Search School Location"
                        class="form-control form-input"
                        country='IN'
                        types=""
                        v-on:placechanged="getAddressData"
                      >
                      </vue-google-autocomplete>
                      <label for="countryid" 
                      >Search Address</label
                    >
                    <!-- <datalist id="countries">
                      <option value="India">India</option>
                    </datalist> -->
                  </span>
                  </div>
                </div>
                <div style="text-align:center">
                  <button
                    type="submit"
                    class="web-custbutton mt-3"
                    @click="submitSearch"
                  >
                    Search
                  </button>

                  <button class="web-cancelbutton mt-3 ml-2" @click="reset">
                    Reset
                  </button>
                </div>
              </div>
              <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);"
              />
            </div>

            <div id="tjSearch" class="web-hmtabcontent">
              <form action="" method="post" accept-charset="utf-8">
                <div class="form-row">
                  <div class="form-group input-group col-sm-6 pr-md-3">
                    <span class="web-has-float-label">
                      <input
                        type="text"
                        name="sname"
                        class="form-control web-form-input"
                        id="snameid"
                        placeholder="School Name"
                        required="required"
                      />
                      <label for="snameid"
                        >School Name <span class="required">*</span></label
                      >
                    </span>
                  </div>
                  <div class="form-group input-group col-sm-6 ">
                    <span class="web-has-float-label">
                      <input
                        list="schcate"
                        class="form-control web-form-input"
                        id="schcateid"
                        placeholder="School Category"
                        required="required"
                      />
                      <label for="schcateid"
                        >School Category<span class="required">*</span></label
                      >
                      <datalist id="schcate">
                        <option value="CBSE">CBSE</option>
                        <option value="International">International</option>
                        <option value="ICSE">ICSE</option>
                        <option value="Government">Government</option>
                        <option value="Government Aided"
                          >Government Aided</option
                        >
                      </datalist>
                    </span>
                  </div>
                </div>
                <div class="form-row mt-4">
                  <div class="form-group input-group col-sm-6 pr-md-3">
                    <span class="web-has-float-label">
                      <input
                        list="forclass"
                        class="form-control web-form-input"
                        id="forclassid"
                        placeholder="For Class"
                        required="required"
                      />
                      <label for="forclassid"
                        >For Class<span class="required">*</span></label
                      >
                      <datalist id="forclass">
                        <option value="I STD">I STD</option>
                        <option value="II STD">II STD</option>
                        <option value="III STD">III STD</option>
                      </datalist>
                    </span>
                  </div>
                  <div class="form-group input-group col-sm-6 ">
                    <span class="web-has-float-label">
                      <input
                        list="seldist"
                        value=""
                        class="form-control web-form-input"
                        id="seldistid"
                        placeholder="Select Location"
                        required="required"
                      />
                      <label for="seldistid"
                        >Select Location<span class="required">*</span></label
                      >
                      <datalist id="seldist">
                        <option value="Chennai">Chennai</option>
                        <option value="Coimbatore">Coimbatore</option>
                      </datalist>
                    </span>
                  </div>
                </div>
                <div style="text-align:center">
                  <button type="submit" class="web-custbutton mt-3">Search</button>

                  <button class="web-cancelbutton mt-3 ml-2">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <img
          src="../../assets/img/home-banner.svg"
          class="img-fluid heroimg"
          width="550"
          alt="Vidhyaan"
          title="Vidhyaan"
        />
      </div>
      <div class="web-triangleblue web-svg-image wow web-fadeIn" data-wow-delay="0.5s">
        <img src="../../assets/img/angleblue.png" />
      </div>
      <div class="web-trianglecont web-svg-image wow web-fadeIn" data-wow-delay="0.5s">
        <!-- <img src="../../assets/img/triangle.png" /> -->
      </div>
    </section>
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Select from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "vi-home-hero-search",
  components: {
    ViSpinner,
    VueGoogleAutocomplete,
    "v-select": Select,
  },
  data() {
    return {
      sname: "",
      scategory: null,
      slocation: null,
      serviceError: null,
      viLoader: null,
      state: null,
      city: null,
      district: null,
      street: null,
      accountList: [],
      showOption: false,
      countAll: true,
      lastPageNumber: 1,
       skip: 0,
      limit: 7,
      stateList: {
        AN : 'Andaman and Nicobar Islands',
        AP : 'Andhra Pradesh',
        AR	: 'Arunachal Pradesh',
        AS : 'Assam',
        BR : 'Bihar',
        CH : 'Chandigarh',
        CT : 'Chhattisgarh',
        DN : 'Dadra and Nagar Haveli',
        DD : 'Daman and Diu',
        DL : 'Delhi',
        GA : 'Goa',
        GJ : 'Gujarat',
        HR : 'Haryana',
        HP : 'Himachal Pradesh',
        JK : 'Jammu and Kashmir',
        JH : 'Jharkhand',
        KA : 'Karnataka',
        KL : 'Kerala',
        LD : 'Lakshadweep',
        MP : 'Madhya Pradesh',
        MH : 'Maharashtra',
        MN : 'Manipur',
        ML : 'Meghalaya',
        MZ : 'Mizoram',
        NL : 'Nagaland',
        OR : 'Odisha',
        PY : 'Puducherry',
        PB : 'Punjab',
        RJ : 'Rajasthan',
        SK : 'Sikkim',
        TN : 'Tamil Nadu',
        TG : 'Telangana',
        TR : 'Tripura',
        UP : 'Uttar Pradesh',
        UT : 'Uttarakhand',
        WB : 'West Bengal'
      },
    };
  },

  mounted() {
    document.getElementById("map").innerHTML = document.getElementById("map").innerHTML;
    this.$refs.address.focus();
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
  },
  
  methods: {
    
     clearAddress(addressData, placeResultData, id){
      if(addressData.newVal == '' || addressData.newVal == null){
        this.street = null,
        this.state = null,
        this.district = null,
        this.city = null
      }
     
    },
    getAddressData(addressData, placeResultData, id) {
     
      this.street = addressData.route || '',
      this.state = addressData.administrative_area_level_1 || '',
      this.district = addressData.administrative_area_level_2 || '',
      this.city = addressData.locality || ''

      if(this.state){
        this.state = this.stateList[this.state]
      }

    },
     async getSchoolList(search,loading) {
     
      if(search && search != '' && search != null && search != null) {
        const response = await ViService.viXGet(
        `/common/allschool?skip=${this.skip}&limit=${this.limit}&search=${search}&isCount=${this.countAll}`
      );
      if (response.isSuccess) {
        const resultData = secureUI.secureGet(response.data);
        this.accountList = resultData.accountList;
        if(resultData.accountList.length == 0){
           this.showOption = true
        }
        if (this.countAll) {
          this.totalCount = resultData.totalCount;
          this.lastPageNumber = Math.ceil(this.totalCount / this.limit);
        }
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
      } else {
        this.accountList = [];
        this.showOption = false
         
      }
      
    },
    /* home search section */
    openhmSearch(evt, srcName) {
      // let i, web-tabcontent, web-tablinks;
      // web-tabcontent = document.getElementsByClassName("web-hmtabcontent");
      // for (i = 0; i < web-tabcontent.length; i++) {
      //   web-tabcontent[i].style.display = "none";
      // }
      // web-tablinks = document.getElementsByClassName("hmtablinks");
      // for (i = 0; i < web-tablinks.length; i++) {
      //   web-tablinks[i].className = web-tablinks[i].className.replace(" active", "");
      // }
      // document.getElementById(srcName).style.display = "block";
      // evt.currentTarget.className += " active";
    },
    clear() {
      this.$toasted.clear();
      this.sname = "";
      this.scategory = null;
      this.slocation = null;
      this.serviceError = null;
    },
    submitSearch() {
      this.$toasted.clear();
      //  if(this.sname != null && this.sname != "" && this.sname != undefined ){
      let requestBody = {
        schoolName: this.sname.accountName,
        category: this.scategory,
        state: this.state,
        city: this.city,
        district: this.district,
        street: this.street,
      };
      this.viLoader = true;
      ViService.viPost("/app/school/parentPortal", requestBody)
        .then((res) => {
          this.viLoader = null;
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (data && data.length > 0) {
              this.$store.dispatch("setSchoolList", data);
              this.$router.push('/parent/search-school');
            } else {
              this.$toasted.error("Records not found");
            }
          } else {
            this.$toasted.error(res.message);
          }
        })
        .catch((e) => {
          this.viLoader = null;
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
        //  }else{
        //   this.$toasted.error("Please search school name and select");
        // }
    },
    reset() {
      this.clear();
      this.$refs.address.$refs.autocomplete.value = ''
    },
  },
};
</script>

<style lang="scss" scoped></style>
